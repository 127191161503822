import { useState, useEffect, useRef } from "react";
import Suggestions from "../../pages/referendums/Suggestions";
import { motion } from "framer-motion";
import MetadataSetup from "../MetadataSetup.js";
import Log from "../analytics/Log.jsx";

import { dateNowByServer, getVotingDurationByOrigServer } from "../../helpers/date.js";
// import { useNavigate } from "react-router-dom";
import topReferendumCacheHelper from "../../helpers/topReferendumCacheHelper";
import hotRefsCacheHelper from "../../helpers/hotRefsCacheHelper";
import trendingRefsCacheHelper from "../../helpers/trendingRefsCacheHelper";
import helpThemOutRefsCacheHelper from "../../helpers/helpThemOutRefsCacheHelper";
import axiosConfig from "../../helpers/axiosConfig";
import auth from "../../helpers/auth";
import searchIcon from "../../assets/images/home/search.svg";
import Footer from "../layouts/footer.js";
import { hasMainContentScroll } from "../../helpers/jsHelper";
import { useSelector, useDispatch } from "react-redux";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
import moment from "moment";
import { setOrigServerTime } from "../../helpers/serverTime.js";

function Main() {
  // const navigate = useNavigate();
  const search_key_ref = useRef();
  const origServerTime = useSelector((state) => state.serverTime.origTime);
  const dispatch = useDispatch();
  const [topRefs, setTopRefs] = useState([]);
  const [hotRefs, setHotRefs] = useState([]);
  const [trendingRefs, setTrendingRefs] = useState([]);
  const [helpThemOutRefs, setHelpThemOutRefs] = useState([]);

  const [topStatus, setTopStatus] = useState("updating");
  const [hotStatus, setHotStatus] = useState("updating");
  const [trendingStatus, setTrendingStatus] = useState("updating");
  const [helpStatus, setHelpStatus] = useState("updating");



  window.addEventListener("resize", () => {
    hasMainContentScroll("main-home");
  })

  useEffect(
    () => {
      let cacheServerTime = serverTimeHelper.get();
      if (origServerTime !== null || cacheServerTime !== null) {
        if (origServerTime === null) {
          dispatch(setOrigServerTime(cacheServerTime))
        }
        fetchTopRefs();
        fetchHotRefs();
        fetchTrendingRefs();
        fetchHelpThemOutRefs();
        hasMainContentScroll("main-home");
      }
    },
    // eslint-disable-next-line
    [origServerTime]
  );

  async function fetchTopRefs() {
    let toprefcache = topReferendumCacheHelper.get();
    if (toprefcache.length !== 0) {
      setTopStatus("updated");
      setupSuggestions(toprefcache, setTopRefs);
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=top" : "https://api.onepeople.online/op/v1/referendums/suggestions?group=top";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setTopRefs);
          topReferendumCacheHelper.set(res.data.referendums);
        }
      })
      .catch((errs) => {

        auth.checkErrors(errs);
      })
      .finally(() => {
        setTopStatus("updated");
        hasMainContentScroll("main-home")
      });
  }

  async function fetchHotRefs() {
    let hotrefcache = hotRefsCacheHelper.get();

    if (hotrefcache.length !== 0) {
      setHotStatus("updated");
      setupSuggestions(hotrefcache, setHotRefs);
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=hot" : "https://api.onepeople.online/op/v1/referendums/suggestions?group=hot";

    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setHotRefs);
          hotRefsCacheHelper.set(res.data.referendums);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
      })
      .finally(() => {
        setHotStatus("updated");
        hasMainContentScroll("main-home")
      });
  }

  async function fetchTrendingRefs() {
    let trendingCache = trendingRefsCacheHelper.get();

    if (trendingCache.length !== 0) {
      setTrendingStatus("updated");

      setupSuggestions(trendingCache, setTrendingRefs);
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=trending" : "https://api.onepeople.online/op/v1/referendums/suggestions?group=trending";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setTrendingRefs);
          trendingRefsCacheHelper.set(res.data.referendums);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
      })
      .finally(() => {
        setTrendingStatus("updated");
        hasMainContentScroll("main-home")
      });
  }

  async function fetchHelpThemOutRefs() {
    let trendingCache = helpThemOutRefsCacheHelper.get();

    if (trendingCache.length !== 0) {
      setHelpStatus("updated");
      setupSuggestions(trendingCache, setHelpThemOutRefs);
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?group=help" : "https://api.onepeople.online/op/v1/referendums/suggestions?group=help";
    axiosConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setHelpThemOutRefs);
          helpThemOutRefsCacheHelper.set(res.data.referendums);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
      })
      .finally(() => {
        setHelpStatus("updated");
        hasMainContentScroll("main-home")
      });
  }

  const setupSuggestions = (refs, setRef) => {
    refs = refs.slice(0, 8).map((ref, index) => {

      let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
      let tempStatus = getVotingDurationByOrigServer(ref.end_at, dateNow);
      ref = { ...ref, ...tempStatus };
      return ref;
    });

    setRef(refs);
  };

  function onSearch(e) {
    e.preventDefault();
    if (search_key_ref.current.value !== "") {
      window.location.href = `/search?q=${search_key_ref.current.value}`;
    } else {
      // navigate(`/search`);
      window.location.href = `/search/`;
    }
  }

  return (
    <Log logImpression event="page-view" page="Home">
      <MetadataSetup
        title="OnePeople: Vote, and share."
        canonical="https://onepeople.online/"
        description="Tap into the wisdom of the crowds, and learn something new today!"
      />
      <div className="main main-home">
        <div className="top home">
          <h1 className="home-header-txt d-none d-md-block">
            What would you like to know?
          </h1>
          <form onSubmit={onSearch} className="search-form">
            <div>
              <img
                src={searchIcon}
                alt="search icon"
                className="icon"
              />
              <input
                className="hl-border-xs"
                ref={search_key_ref}
                type="text"
                name="key"
                placeholder="Search referendums for topics or keywords"
              />
            </div>
          </form>
        </div>
        <div className="center row mt-0 mt-md-0 mt-lg-4 pt-4 pb-3">
          <motion.div
            className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-4 "
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Suggestions
              url="/top/"
              updateStatus={topStatus}
              small={false}
              referendums={topRefs}
              title="Top Referendums"
              errorMsg={`No Top Referendums Found`}
              unicode="&#x1F3C6;"
              limit={8}
            />
          </motion.div>
          <motion.div
            className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Suggestions
              url="/hotly-contested/"
              updateStatus={hotStatus}
              small={false}
              referendums={hotRefs}
              title="Hotly Contested"
              errorMsg={`No Hotly Contested Found`}
              unicode="&#128293;"
              limit={8}
            />
          </motion.div>

          <motion.div
            className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-4 mb-md-4 mb-lg-0 mb-xxl-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Suggestions
              url="/trending/"
              updateStatus={trendingStatus}
              small={false}
              referendums={trendingRefs}
              title="Trending"
              unicode="&#128200;"
              errorMsg={`No Trending Found`}
              limit={8}
            />
          </motion.div>

          <motion.div
            className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-3 mb-0 mb-xxl-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Suggestions
              url="/help-them-out/"
              updateStatus={helpStatus}
              small={false}
              referendums={helpThemOutRefs}
              title="Help Them Out"
              errorMsg={`No Help Them Out Found`}
              unicode="&#128075;"
              limit={8}
            />
          </motion.div>
        </div>
      </div>
      <Footer customClass={"inside-footer"} />
    </Log>
  );
}

export default Main;
