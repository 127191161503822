import { useState, useEffect } from "react";
import auth from "../../../helpers/auth";
import validator from "validator";
import { motion } from "framer-motion";
import InputCounter from "../../../pages/referendums/InputCounter";
import axiosConfig from "../../../helpers/axiosConfig"
import { sendLog } from "../../../analytics/analytics.js";

function VotingForm(props) {
  const {
    referendum,
    setReferendum,
    setSubmittedReason,
    showReason,
    setShowReason,
  } = props;
  const [options, setOptions] = useState(referendum.options);
  const [newOption, setNewOption] = useState(false);

  const initErrors = {
    choice: null,
    reason: null,
    others: null,
  };

  const [voting, setVoting] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState(initErrors);

  useEffect(
    () => {
      let option = referendum.options.filter((option, index) => {
        return option.reason;
      });
      if (option.length > 0) {
        setReason(option[0].reason.reason);
        setSubmittedReason(option[0].reason.reason);
        document.getElementById("ed-root").classList.add("user-voted")
      }
    },
    // eslint-disable-next-line
    []
  );

  function onChangeChoice(e) {
    if (!auth.isOnline()) {
      document.getElementsByClassName("join-btn")[0].click();
    } else {
      let option = e.target.id.replace("option-", "");
      let update_options = options.map((opt, index) => {
        // eslint-disable-next-line
        if (opt.id == option) {
          opt.active = true;
          opt.reason = null;
        } else {
          opt.active = false;
        }
        opt.reason = null;
        return opt;
      });
      setOptions(update_options);
      setNewOption(true);
      setErrors({ ...errors, choice: null, others: null });
      setShowReason(true);
    }
  }

  function onChangeReason(e) {
    let value = e.target.value;
    if (validator.isLength(value, { min: 0, max: 300 })) {

      setReason(value);
      setErrors({ ...errors, reason: null, others: null });
    }
  }

  function onSubmitReason(e) {
    e.preventDefault();

    setErrors(initErrors);

    let option = options.filter((option, index) => {
      return option.active;
    });

    if (option.length === 0) {
      option = options.filter((option) => option.reason !== null);
    }

    let errorTrackers = { ...errors };
    let hasErrors = false;

    if (option.length === 0) {
      errorTrackers.choice = "Please select a valid choice";
      hasErrors = true;
    }
   
    if (validator.isEmpty(reason.trim())) {
      errorTrackers.reason = "Please enter the top reason for your choice.";
      hasErrors = true;
    } else if (!validator.isLength(reason, { min: 1, max: 300 })) {
      errorTrackers.reason =
        "Your provided reason is too long. (max: 300 characters)";
      hasErrors = true;
    }

    setErrors(errorTrackers);
    if (hasErrors) return;
    if (voting) return;
    setVoting(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${referendum.slug}/vote`:  `https://api.onepeople.online/op/v1/referendums/${referendum.slug}/vote`;
    axiosConfig
      .post(
        url,
        {
          option: option[0].id,
          reason,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setReferendum((old_ref) => {
            let new_ref = { ...old_ref };
            new_ref.options = options;
            return new_ref;
          });

          sendLog({ event: "click_vote", page: referendum.title, reason, elementId: "submit-vote", elementType: "button", elementText: "Submit Vote" })
          setSubmittedReason(reason);
          setShowReason(false);
          document.getElementById("ed-root").classList.add("user-voted")
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        let errs = err.response.data.errors;
        if (errs) {
          if (errs[0].msg === "connect ETIMEDOUT") {
            errorTrackers.others = "Something went wrong. Please try again.";
          } else {
            errs.map((err, index) => {
              let param = err.path;
              if (param) {
                if (param === "option") {
                  errorTrackers.option = err.msg;
                } else {
                  errorTrackers.reason = err.msg;
                }
              } else {
                errorTrackers.others = err.msg;
              }
              return err;
            });
          }

          setErrors(errorTrackers);
        } else {
          errorTrackers.others =
            "There was a problem submitting your vote. Please referesh the page and try again.";
        }
      })
      .finally(() => {
        setVoting(false);
      });
  }

  return (
    <form>
      <div className="ref-choices">
        {referendum &&
          options.map((option, index) => {
            return (
              <div className="ref-choice" key={index}>
                <label
                  htmlFor={`option-${option.id}`}
                  className={`ref-choice-label px-5 py-2 text-center ${
                    (option.active && newOption) || (referendum.voted_id && referendum.voted_id === option.id && !newOption) ? "active" : ""
                  }`}
                >
                  {option.option}
                </label>
                <input
                  onChange={onChangeChoice}
                  value={option.option}
                  type="radio"
                  name="option"
                  id={`option-${option.id}`}
                  className="d-none"
                />
              </div>
            );
          })}
      </div>
      {showReason && (
        <>
          <hr className="mt-5" />
          <div className="ref-reason row justify-content-center mt-5 position-relative">
            <label htmlFor="option-reason" className="col-10 col-md-7 col-lg-5 px-0 text-black">
              Enter the top reason for your choice:
            </label>
            <div className="w-100 mt-1"></div>
            <textarea
              value={reason}
              onChange={onChangeReason}
              id="option-reason"
              className="col-10 col-md-7 col-lg-5"
              rows="6"
            ></textarea>
            <div className="w-100"></div>
            <div className="col-10 col-md-7 col-lg-5 position-relative">
              <InputCounter max={300} inputText={reason} />
            </div>
          </div>
          <div className="m-0 p-0 d-flex justify-content-center">
            {errors.choice && (
              <p className="m-0 p-0 input-text input-error text-red">
                {errors.choice}
              </p>
            )}
            {errors.reason && (
              <p className="m-0 p-0 input-text input-error text-red">
                {errors.reason}
              </p>
            )}
            {errors.others && (
              <p className="m-0 p-0 input-text input-error text-red">
                {errors.others}
              </p>
            )}
          </div>

          <div className="d-flex justify-content-center pb-4">
            <motion.input
              initial={{}}
              animate={
                voting ? { backgroundColor: "#ccc", cursor: "not-allowed" } : {}
              }
              onClick={onSubmitReason}
              type="submit"
              name="submit"
              value="Submit Vote"
              id="submit-vote"
              className="mt-5 op-btn submit-btn bounce"
            />
          </div>
        </>
      )}
    </form>
  );
}

export default VotingForm;
