import { AES, enc, SHA256 } from "crypto-js";

// Set a cookie with the given name, value, and expiration time
function setCookie(key, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    const encrypted = AES.encrypt(value, process.env.REACT_APP_MESSAGE_SECRET).toString();
    const name_encrypted = SHA256(key.toString()).toString();
    document.cookie = name_encrypted + "=" + encrypted + expires + "; path=/; secure; samesite=strict";
}

// Get the value of a cookie with the given name
function getCookie(key) {
    const name_encrypted = SHA256(key.toString()).toString();
    var nameEQ = name_encrypted + "=";
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) === 0) {
            let temp_val = cookie.substring(nameEQ.length, cookie.length)
            const decrypted = AES.decrypt(temp_val, process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)

            return decrypted;
        }
    }
    return null;
}

function setLocal(key, data) {
    const encrypted = AES.encrypt(JSON.stringify(data), process.env.REACT_APP_MESSAGE_SECRET).toString();
    localStorage.setItem(SHA256(`${key}`).toString(), JSON.stringify(encrypted));
    // localStorage.setItem(key, JSON.stringify(data));
}

function getLocal(key) {
    const data = localStorage.getItem(SHA256(`${key}`).toString());
    // return JSON.parse(data);
    if (data == null) {
        return null;
    }
    const decrypted = AES.decrypt(JSON.parse(data), process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
    return JSON.parse(decrypted);
}

function removeLocal(key) {
    localStorage.removeItem(SHA256(`${key}`).toString());
}


function setSession(key, data) {
    const encrypted = AES.encrypt(JSON.stringify(data), process.env.REACT_APP_MESSAGE_SECRET).toString();
    sessionStorage.setItem(SHA256(`${key}`).toString(), JSON.stringify(encrypted));
    // sessionStorage.setItem(key, JSON.stringify(data));
}

function getSession(key) {
    const data = sessionStorage.getItem(SHA256(`${key}`).toString());
    // return JSON.parse(data);
    if (data == null) {
        return null;
    }
    const decrypted = AES.decrypt(JSON.parse(data), process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
    return JSON.parse(decrypted);
}

function removeSession(key) {
    sessionStorage.removeItem(SHA256(`${key}`).toString());
}

export {
    setCookie,
    getCookie,
    setLocal,
    getLocal,
    removeLocal,
    setSession,
    getSession,
    removeSession,
}