import { useEffect, useState } from "react";
import auth from "../../../helpers/auth";
import axiosConfig from "../../../helpers/axiosConfig"
import thumbsUpDefault from "../../../assets/images/referendum/thumbs-up-default.svg"
import thumbsUpSelected from "../../../assets/images/referendum/thumbs-up-selected.svg"
import { LineBreaker } from "css-line-break"
import { useEventListener } from "usehooks-ts";
import { sendLog } from "../../../analytics/analytics.js";

function Result(props) {
  const { referendum, setReferendum, option, tag } = props;
  const [updating, setUpdating] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  
  const [optionText, setOptionText] = useState(option.option);

  useEffect(() => {
    setupLineBreaking();
  }, 
  // eslint-disable-next-line
  []);

  useEventListener('resize', setupLineBreaking)

  

  function getBreakingPoint() {
    let fontConstant = 1.85;
    let fontSize = window ? Number(window?.getComputedStyle(document.getElementById("ref_res_option")).getPropertyValue('font-size').match(/\d+/)[0]) : 28;
    // console.log(fontSize, '--------------------------------------------------------------------------fontsize');
    let width = document.getElementById("ref_res_option").offsetWidth - Number(window?.getComputedStyle(document.getElementById("ref_res_option")).getPropertyValue('margin-left').match(/\d+/)[0]);

    return Math.ceil(width / (fontSize / fontConstant));
  }

  function breakOptionText(text) {
    const breaker = LineBreaker(text, { lineBreak: "normal", wordBreak: "normal" });

    let words = [];
    let bk;

    while (!(bk = breaker.next()).done) {
      words.push(bk.value.slice());
    }

    return words;
  } 

  function setupLineBreaking() {
    // if (option.option === 'Wage supplement/training/job loss support schemes') {
      let charsPerLine = getBreakingPoint();
      setWordBreaking2(charsPerLine);
    // } 
  }

  //abcdefghijklmnopqrstuvwxyz
  function getWordLength(word) {
    let temp = word;
    let shortLetterOccurrence = word.length - temp.replace(/[Iiljtr']/g, "").length;
    let wideLetterOccurrence = word.length - temp.replace(/[WwMm]/g, "").length;
    return word.length - Math.floor(((shortLetterOccurrence) / 2) + ((wideLetterOccurrence) * 1.3));
  }

  function setWordBreaking2(charsPerLine) {

    try {
      let words = breakOptionText(option.option);
      let val = words.join("");

      // console.log(words.length, '----words.length')

      if(words.length > 0) {

        let currLenghAll = 0;

        // console.log(charsPerLine, '-----------charsPerLine')
        let prev_word = null;

        words = words.map((word, index) => {

          let current_word_length = getWordLength(word);

          // console.log('----------start----------')
          // console.log(word, '-------word')
          // console.log(current_word_length, '-------word length')
          // console.log(index, '----index');
          // console.log(currLenghAll, '----currLengthAll')
        
          if(/\s$/.test(word)) {
            // console.log("with space at the end")
            //means NOT connected to next word
            let expected_length = currLenghAll + current_word_length;
            // console.log(expected_length, '----expected length')

            if(currLenghAll === 0  && prev_word && !/\s$/.test(prev_word)) {
              word = "\n" + word;
              currLenghAll = (current_word_length + 1) % charsPerLine;
            } else {
              if(expected_length === charsPerLine) {
                currLenghAll = 0;
              } else if (expected_length < charsPerLine) {
                currLenghAll = expected_length;
              } else {
                if(prev_word && /\s$/.test(prev_word)) {
                  currLenghAll = current_word_length % charsPerLine;
                } else {
                  word = "\n" + word;
                  currLenghAll = (current_word_length + 1) % charsPerLine;
                }
              }
            }
          } else {
            //word did not end with space which mean it is connected to next word
            //check if word is connected to prev
            if(prev_word && /\s$/.test(prev_word)) {
              // console.log('-----no end space and not connected to prev')
              //not connected as prev word ends with space
              let expected_length = currLenghAll + current_word_length;
              // console.log(expected_length, '----expected length')
              if(expected_length === charsPerLine) {
                currLenghAll = 0;
              } else if(expected_length < charsPerLine) {
                currLenghAll = expected_length;
              } else {
                if(current_word_length === charsPerLine) {
                  // console.log('word length is equal to char per line')
                  currLenghAll = 0;
                } else {
                  if(index === (words.length - 1)) {
                    word = '' + word
                    currLenghAll = (current_word_length + 1) % charsPerLine;
                  } else {
                    word = '\n' + word
                    currLenghAll = (current_word_length + 1) % charsPerLine;
                  }
                }
              }
            } else {
              //connected as prev word did not end with space
              // console.log('-----no end space and connected to prev')
              let expected_length = currLenghAll + current_word_length;
              // console.log(expected_length, '----expected length')
              if(index === 0) {
                currLenghAll = current_word_length % charsPerLine;
              } else if(currLenghAll === 0 && prev_word && prev_word.length === charsPerLine) {
                word = '\n' + word
                currLenghAll = (current_word_length + 1) % charsPerLine;
              } else {
                if(expected_length === charsPerLine) {
                  currLenghAll = 0;
                } else if (expected_length < charsPerLine) {
                  currLenghAll = expected_length;
                } else {
                  word = '\n' + word
                  currLenghAll = (current_word_length + 1) % charsPerLine;
                }
              }
            }
          }

          prev_word = word;
          return word;
        })

        val = words.length ? words.join("") : null;
        setOptionText(val)
      }
    } catch (err) {
      setOptionText(option.option)
    }
  }

  function handleInsight(id) {
    if (auth.isOnline()) {
      // let errorTracker = error;
      if (updating) return;
      setUpdating(true);
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${referendum.slug}/insights`:`https://api.onepeople.online/op/v1/referendums/${referendum.slug}/insights`;
      axiosConfig
        .post(
          url,
          {
            reason: id,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            let reason_selected;
            let approved = true;
            setReferendum((old_ref) => {
              let new_ref = { ...old_ref };
              new_ref.options.forEach((option) => {
                if (option.reasons.length > 0) {
                  option.reasons.forEach((reason) => {
                    if (reason.id === id) {
                      reason.is_insightful = res.data.is_insightful;
                      if (res.data.is_insightful) {
                        reason.insights++;
                        approved = true;
                      } else {
                        reason.insights--;
                        approved = false;
                      }
                      reason_selected = { id: reason.id, reason: reason.reason};
                    }
                  });
                }
              });

              return new_ref;
            });
            sendLog({ event: 'click_insight', page: referendum.title, insightApproved: approved, elementId: reason_selected.id, elementType: 'button', elementText: reason_selected.reason})
          }
        })
        .catch((errs) => {
          auth.checkErrors(errs);
          console.log(errs);
        })
        .finally(() => {
          setUpdating(false);
        });
    } else {
      document.getElementsByClassName('join-btn')[0].click();
    }
  }

  return (
    <div
      className={`ref-res-col pt-4 pb-3 ${
        option.winner ? "winner" : "loser"
      }`} style={{height: "100%"}}
    >
      <div className="ref-res-status d-flex justify-content-between">
        <div className="ref-res-status-label">
          <div className="ref-res-tag">{tag}</div>
          <h3 id="ref_res_option" className="ref-res-option mt-3 text-left ms-4">{optionText}</h3>
        </div>
        <div className="ref-res-status-votes">
          <div className={`ref-res-percentage`}>{option.percentage}</div>
          <div className="ref-res-votes">
            {option.votes} {option.votes <= 1 ? "vote" : "votes"}
          </div>
        </div>
      </div>
      <div className="ref-res-reasons px-4 mt-4 text-black">
        <div className="ref-res-reasons-header d-flex justify-content-between mb-1">
          <h5>Top Reasons</h5>
          {option.reasons.length > 0 && auth.isOnline() && <div className="text-center">Insightful?</div>}
          {option.reasons.length > 0 && !auth.isOnline() && <div className="text-center">Insights</div>}
        </div>
        <div className="ref-res-top-reasons">
          {option.reasons.length > 0 &&
            option.reasons.map((reason, index) => {
              return (
                <div
                  className="top-reason d-flex justify-content-between align-items-center mb-1"
                  key={index}
                >
                  <h6 className="reason">{reason.reason}</h6>
                  <div
                    className={`reason-ctr d-flex justify-content-${
                      auth.isOnline() ? "between" : "center"
                    } align-items-center`}
                  >
                    <h6 className="">{reason.insights}</h6>
                    {auth.isOnline() && (
                      <button
                        type="button"
                        onClick={(e) => handleInsight(reason.id)}
                        className="insight-btn bounce"
                      >
                        <img
                          src={` ${
                            reason.is_insightful
                              ? thumbsUpSelected
                              : thumbsUpDefault
                          }`}
                          alt="thumbs up"
                        />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}

          {(option.reasons.length === 0 && option.votes === 0) && (
            <div className="top-reason">
              <p className="reason nr m-0 p-0 text-gray">No reason</p>
            </div>
          )}
          {(option.reasons.length === 0 && option.votes > 0) && (
            <div className="top-reason">
              <p className="reason nr m-0 p-0 text-gray">Unable to retrieve sensible answer</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Result;
