
import React, { useContext, useEffect } from "react";
import { sendLog } from "../../analytics/analytics";

const Context = React.createContext('');
export const LogContext = Context;

function Log(props) {
    const context = useContext(LogContext)
    const combinedProps = {...context, ...props};

    useEffect(() => {
        if(props.logImpression && props.event === 'page-view') {
            sendLog(combinedProps);
        }
    }, [])

    return (
        <LogContext.Provider value={combinedProps}>
            {props.children}
        </LogContext.Provider>
    )
}

export default Log;