import {AES,enc,SHA256} from "crypto-js"; 

var auth = (function () {
  // Set a cookie with the given name, value, and expiration time
  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    const encrypted = AES.encrypt(value, process.env.REACT_APP_MESSAGE_SECRET).toString();
    const name_encrypted = SHA256(name.toString()).toString();
    document.cookie = name_encrypted + "=" + encrypted + expires + "; path=/; secure; samesite=strict";
  }

  // Get the value of a cookie with the given name
  function getCookie(name) {
   const name_encrypted = SHA256(name.toString()).toString();
    var nameEQ = name_encrypted + "=";
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
      let temp_val = cookie.substring(nameEQ.length, cookie.length)
        const decrypted = AES.decrypt(temp_val,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
       
        return decrypted;
      }
    }
    return null;
  }

  // Remove a cookie with the given name
  function removeCookie(name) {
    const name_encrypted = SHA256(name.toString()).toString();
    document.cookie = name_encrypted + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  function isCookieExpired(cookieName) {
    // Retrieve the cookie value
    const cookies = document.cookie.split(';');
    if(cookies.length > 0 && cookies[0].length > 0) {
      const name_encrypted = SHA256(cookieName.toString()).toString();
      const cookie = cookies.find(c => c.trim().startsWith(name_encrypted + '='));
    
      if (cookie) {
        // Extract the expiration date from the cookie
        const cookieParts = cookie.split('=');
        const cookieValue = cookieParts[1];
        const expirationDate = new Date(cookieValue);
    
        // Get the current date and time
        const currentDate = new Date();
    
        // Compare the expiration with the current date
        if (expirationDate <= currentDate) {
          return true; // Cookie has expired
        }
      }
    }
    
  
    return false; // Cookie is still valid
  }

  
  var getToken = function () {
    if(isCookieExpired('token')){
      logout();
      window.location.href = "/";
    }
    return getCookie("token");
  };
  var setToken = function (token) {
    setCookie("token", token, 30); // Set expiration to 3 days
  };
  var removeToken = function () {
    removeCookie("token");
  };
  var getFullName = function (fullname) {
    return getCookie("fullname");
  };
  var setFullName = function (fullname) {
    setCookie("fullname", fullname, 30); // Set expiration to 3 days
  };
  var removeFullName = function (fullname) {
    removeCookie("fullname");
  };
  var getId = function () {
    return getCookie("id");
  };
  var setId = function (id) {
    let ids= id.toString();
    setCookie("id", ids, 30); // Set expiration to 3 days
  };
  // var removeId = function () {
  //   removeCookie("id");
  // };
  
  var logout = function () {
    const cookies = (document.cookie.split(';'))
    const encrypted_consent_name = SHA256("consentAgreement").toString();
    const encrypted_consent_user = SHA256('anonId').toString();
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // if((name !== encrypted_consent_name && name !== (" " + encrypted_consent_name)) && (name !== encrypted_consent_user && name !== (" " + encrypted_consent_user))) {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      // }
    }

    localStorage.clear();
    sessionStorage.clear();
  };
  var isOnline = function () {
    var name = getFullName();
    var token = getToken();

    return (
      name !== null &&
      name !== "" &&
      name !== undefined &&
      token !== null &&
      token !== "" &&
      token !== undefined
    );
  };
  var checkErrors = function(e){
    let isRelogin = false;

    if(e.response.data.message !== undefined) {
      isRelogin = e.response.data.message === 'Invalid CSRF';
    } else if (e.response.data.errors !== undefined) {
      isRelogin =
        e.response.data.errors[0].msg === "invalid signature" ||
        e.response.data.errors[0].msg === "Invalid Token" ||
        e.response.data.errors[0].msg === "jwt expired" ||
        e.response.data.errors[0].msg === "jwt malformed";
    }
    if (isRelogin) {
      auth.logout();
      // navigate("/");
      localStorage.clear();
      window.location.href = "/";
    }
  }
  return {
    getToken,
    setToken,
    removeToken,
    setId,
    getId,
    setFullName,
    getFullName,
    removeFullName,
    isOnline,
    logout,
    checkErrors
  };
})();

export default auth;