import { Card, Row, Col } from "react-bootstrap";
import layout from "../privacy/layout.module.css";
import { useState } from "react";
import auth from '../../helpers/auth';
import MetadataSetup from "../../components/MetadataSetup";
import Log from "../../components/analytics/Log";

export default function PrivacyPolicy() {

  // eslint-disable-next-line
  const [onlineStatus, setOnlineStatus] = useState(auth.isOnline());

  return (
    <Log logImpression event="page-view" page="Privacy Policy">
      <MetadataSetup 
        title="Privacy Policy | OnePeople" 
        canonical="https://onepeople.online/privacy-policy/"
        description="OnePeople.online is committed to protecting and respecting your privacy."
      />
      <Row className={`${onlineStatus ? "" : "main d-grid ref-view reset-logout pt-4 mx-auto " }about-us-container justify-content-center`}>
        <Col md={12} lg={12} xl={11} xxl={9} className={onlineStatus ? "" : "mx-auto"}>
          <Card
            className={`about-us-content op-box OPcard-rounded card-transparent ${layout.maxHeight}  mz-scroll-none pe-1`}
          >
           <div className="mz-scroll">
             <Card.Header className="text-center clear-header py-3 fblack pt-5 ">
              <h1 className="FS25 fbold">Privacy Policy</h1>
            </Card.Header>
            <Card.Body className="pt-4 px-4 p-lg-5">
              <p className={layout.b14}>
                This Privacy Policy details the use and collection of your
                information by the websites (the “sites”) operated and owned by
                Edge Digital. These sites include
                <a href="https://edgedigital.sg" target="_blank" rel="noreferrer"> Edge Digital</a> and{" "}
                <a href="https://onepeople.online">OnePeople</a>.
              </p>
              <p className={layout.b14}>
                Your privacy is important to us. The purpose of this Privacy
                Policy is to inform you of how we handle or process your
                personal data in accordance with the Personal Data Protection
                Act 2012 (“the Act”) and other applicable laws.
              </p>
              <p className={layout.b14}>
                In fact, we prefer not to collect any personal data at all, we
                believe everyone has a right to anonymity online. However, we
                recognise the need to relate online accounts with offline
                identities, not just in accordance with respecting regional
                laws, but also in improving our services to you.
              </p>
              <p className={layout.b14}>
                Please take a moment to read this Privacy Policy so that you
                know and understand the purposes for which we collect, use and
                disclose your Personal Data.
              </p>
              <p className={layout.b14}>
                By interacting with us, submitting information to us or signing
                up for any promotions or events offered by us, you agree and
                consent to Edge Digital, our affiliates, and our respective
                successors-in-title as well as our representatives (collectively
                referred to herein as “Edge Digital”, “us”, “we” or “our”)
                collecting, using, disclosing and sharing amongst themselves
                your Personal Data, and disclosing such Personal Data to Edge
                Digital’s authorised service providers and relevant third
                parties in the manner and for any of the purposes set forth in
                this Privacy Policy. In addition, this Privacy Policy provides
                you with more information on the bases upon which we may
                lawfully collect, use and/or disclose your Personal Data without
                your consent, where permitted by applicable law.
              </p>
              <p className={layout.b14}>
                Edge Digital reserves the right to update or modify this Privacy
                Policy from time to time, and send you notification updates to
                this Privacy Policy in any form as we may deem appropriate.
                While you should check this Privacy Policy, we may also inform
                you of how we process your Personal Data by publishing (or
                amending) this Privacy Policy and, where required by applicable
                law, by posting a notice, or reaching you via other forms of
                communication (e.g. sending you an email) based on the contact
                particulars that we may have of you in our records from time to
                time.
              </p>
              <p className={layout.b14}>
                For avoidance of doubt, this Privacy Policy forms a part of the
                Legal Notice (“Legal Notice”). In the event of any conflict,
                inconsistency or conflict between this Privacy Policy and the
                Legal Notice, the Privacy Policy shall prevail. All defined
                terms contained in the Legal Notice shall apply to this Privacy
                Policy unless otherwise specifically stated.
              </p>
              <Row className="p-4">
                <ol className={layout.orderedList}>
                  <li>
                    <div>
                      <b>Your Personal Data</b>
                    </div>
                    <ol className={layout.orderedList}>
                      <li className={layout.az}>
                        <div className="fbold">
                          In this Privacy Policy, “Personal Data” refer to any
                          data or information about you from which you can be
                          identified either:
                        </div>
                        <ol type="a" className={layout.letterList}>
                          <li>from that data; or</li>
                          <li>
                            from that data and other information to which we
                            have or are likely to have access.
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          Examples of such Personal Data which you may provide
                          to us include (depending on the nature of your
                          interaction with us):
                        </div>
                        <ol type="a" className={layout.letterList}>
                          <li>
                            your name, NRIC, passport or other identification
                            number, telephone number(s), mailing address, email
                            address and any other information relating to you
                            which you have provided us in any forms of
                            interaction with you;
                          </li>
                          <li>
                            information about your use of Edge Digital’s sites
                            and services including cookies, IP address,
                            membership details, but only to the extent that we
                            may identify you from such information;
                          </li>
                          <li>
                            your payment related information, such as your bank
                            account or credit card information, and your credit
                            history; and
                          </li>
                          <li>
                            information about your usage of and interaction with
                            our sites and/or services including computer and
                            connection information, device capability,
                            bandwidth, statistics on page views, and traffic to
                            and from our sites.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div>
                      <b>Collection of Personal Data</b>
                    </div>
                    <ol className={layout.orderedList}>
                      <li className={layout.az}>
                        <div className="fbold">
                          Edge Digital collects your Personal Data in one or
                          more of the following ways:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            when you submit forms or other documents relating to
                            any of our services;
                          </li>
                          <li>
                            when you register for or use any of our services on
                            sites owned or operated by us when you register as a
                            member of sites owned and/or operated by us, or use
                            services on such sites;
                          </li>
                          <li>
                            when you interact with our customer service team;
                          </li>
                          <li>
                            when you use some of our services, e.g. participate
                            in a lucky draw or campaign;
                          </li>
                          <li>
                            when you establish any online accounts with us;
                          </li>
                          <li>when you request that we contact you;</li>
                          <li>
                            when you are contacted by, and respond to, our
                            marketing representatives, agents and other service
                            providers;
                          </li>
                          <li>
                            when you respond to our request for additional
                            Personal Data;
                          </li>
                          <li>
                            when you ask to be included in an email or other
                            mailing list;
                          </li>
                          <li>
                            when you respond to our promotions and other
                            initiatives;
                          </li>
                          <li>when you submit a job application;</li>
                          <li>
                            when we receive references from business partners
                            and third parties, for example, where you have been
                            referred by them; or
                          </li>
                          <li>
                            when you submit your Personal Data to us for any
                            other reason.
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          When you browse our site, you generally do so
                          anonymously, we do not at any of our sites, collect
                          any Personal Data unless you provide such information
                          to us. However, please note that we may use cookies
                          and other related technologies that are implemented on
                          our site, for the purposes of understanding our
                          traffic better.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          If you provide us with any Personal Data relating to a
                          third party (e.g. information of spouse, children,
                          parents, employees and/or authorised representatives),
                          by submitting such information to us, you represent to
                          us that you have obtained the consent of the third
                          party to you providing us with their Personal Data for
                          the respective purposes.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          You should ensure that all Personal Data submitted to
                          us is complete, accurate, true and correct. We may not
                          be able to provide you with products and services you
                          have requested or process any requests made to us by
                          you if you fail you provide accurate information.
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div>
                      <b>
                        Purposes for the Collection, Use and Disclosure of Your
                        Personal Data
                      </b>
                    </div>
                    <ol className={layout.orderedList}>
                      <li className={layout.az}>
                        <div className="fbold">
                          Edge Digital collects, uses and discloses your
                          Personal Data for the following purposes:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            responding to, processing and handling your queries,
                            requests, complaints, and feedback;
                          </li>
                          <li>
                            managing the infrastructure and business operations
                            of Edge Digital and complying with internal policies
                            and procedures;
                          </li>
                          <li>
                            facilitating business asset transactions (which may
                            extend to any merger, acquisition or asset sale);
                          </li>
                          <li>
                            matching any Personal Data held which relates to you
                            for any of the purposes listed herein;
                          </li>
                          <li>verifying your identity;</li>
                          <li>
                            preventing, detecting and investing crime, including
                            fraud and money-laundering, and analysing and
                            managing other commercial risks;
                          </li>
                          <li>
                            protecting and enforcing our contractual and legal
                            rights and obligations;
                          </li>
                          <li>
                            conducting audits, reviews and analysis of our
                            internal processes, action planning and managing
                            commercial risks;
                          </li>
                          <li>
                            preventing, detecting and investigating crime and
                            managing the safety and security of our premises and
                            services;
                          </li>
                          <li>
                            compliance with any applicable rules, laws and
                            regulations, codes of practice or guidelines or to
                            assist in law enforcement and investigations by
                            relevant authorities; and/or
                          </li>
                          <li>
                            any other purpose relating to or reasonably
                            necessary for any of the above.
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          In addition, we may also collect, use and disclose
                          your Personal Data for the following purposes,
                          depending on the nature of our relationship with you:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            <div>if you have an account with us:</div>
                            <ol className={layout.AlphaNumericList}>
                              <li>to maintain your account with us;</li>
                              <li>
                                to verify and process your personal particulars
                                with payments in relation to provision of
                                services;
                              </li>
                              <li>
                                to provide you with the goods and services which
                                you have signed up for, if any;
                              </li>
                              <li>
                                communicating with you to inform you of changes
                                and developments to Edge Digital’s terms and
                                conditions and other administrative information,
                                including for the purposes of servicing you in
                                relation to products and services offered to
                                you;
                              </li>
                              <li>
                                resolving complaints and handling requests and
                                enquiries;
                              </li>
                              <li>
                                conducting market research for statistical,
                                profiling and statistical analysis for the
                                improvement of services provided to you; and/or
                              </li>
                              <li>
                                any other purpose relating to or reasonably
                                necessary for any of the above;
                              </li>
                            </ol>
                          </li>
                          <li>
                            <div>
                              if you submit an application to us as a candidate
                              for employment or an internship:
                            </div>
                            <ol className={layout.AlphaNumericList}>
                              <li>
                                processing your application including
                                pre-recruitment checks;
                              </li>
                              <li>
                                providing or obtaining employee references or
                                other references where relevant for background
                                screening/vetting;
                              </li>
                              <li>
                                collecting information about your suitability
                                for the position applied for; organising
                                training and staff development programs;
                              </li>
                              <li>assessing your performance;</li>
                              <li>
                                administrating benefits and payroll processing;
                              </li>
                              <li>
                                providing you with tools to facilitate or as
                                required for you to do your job;
                              </li>
                              <li>
                                communicating with you as required by Edge
                                Digital to comply with its policies and
                                processes, including for business continuity
                                purposes; and/or
                              </li>
                              <li>
                                any other purpose relating to or reasonably
                                necessary for any of the above; and/or
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          In addition, where permitted under applicable laws, we
                          may also collect, use and disclose your Personal Data,
                          and share your Personal Data with Edge Digital’s
                          business partners, service providers and/or other
                          third parties for the following purposes (which we may
                          describe in our documents and agreements as
                          “Additional Purposes” for the handling of Personal
                          Data):
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            providing services, products and benefits to you,
                            including promotions, loyalty and reward programmes;
                          </li>
                          <li>
                            matching Personal Data with other data collected for
                            other purposes and from other sources (including
                            third parties) in connection with the provision or
                            offering of products and services, whether by Edge
                            Digital or other third parties;
                          </li>
                          <li>
                            administering contests, competitions and conducting
                            lucky draws, including, where necessary, in order to
                            announce the results of these contests, competitions
                            and lucky draws;
                          </li>
                          <li>
                            sending you details of products, services, special
                            offers and rewards, either to our customers
                            generally, or of particular products and services,
                            special offers and rewards which may be of interest
                            to you; and/or
                          </li>
                          <li>
                            conducting market research, data analytics,
                            understanding and determining customer location,
                            preferences and demographics for us, our service
                            providers and/or other third parties to develop
                            insights into the industry, review, develop and
                            improve our, our business partners’, service
                            providers’ and/or other third parties’ products,
                            services and also develop special offers and
                            marketing programmes.
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          If you have provided your telephone number(s) and/or
                          email and have indicated that you consent to receiving
                          marketing or promotional information, then from time
                          to time, we may contact you using such telephone
                          number(s) (including via voice calls, SMS, fax or
                          other means) and/or email with information about our
                          products or services (including special offers).
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          In relation to particular products or services or in
                          your interactions with us, we may also have
                          specifically notified you of other purposes for which
                          we collect, use or disclose your Personal Data. If so,
                          then we will collect, use and disclose your Personal
                          Data for these purposes as well.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          You may withdraw your consent for receiving marketing
                          or promotional materials/communication by contacting
                          us using the contact provided in our{" "}
                          <a href="/legal-notice">Legal Notice</a>. Once we
                          receive confirmation that you wish to withdraw your
                          consent for marketing or promotional
                          materials/communication, it may take up to 30 calendar
                          days for your withdrawal to be reflected in our
                          systems. Therefore, you may still receive marketing or
                          promotional materials/communication during this period
                          of time. Please note that we may still contact you for
                          other purposes in relation to our service.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          You also consent to the collection, use and disclosure
                          of your Personal Data by Edge Digital in connection
                          with any transaction relating to the acquisition,
                          divestment, securitisation, amalgamation, listing or
                          other transaction relating to any interest in Edge
                          Digital.
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4 ">
                    <div>
                      <b> Disclosure of Personal Data</b>
                    </div>
                    <ol className={layout.orderedList + " FS14"}>
                      <li className={layout.az}>
                        <div className="fbold ">
                          Edge Digital will take reasonable steps to protect
                          your Personal Data against unauthorised disclosure.
                          Subject to the provisions of any applicable law, this
                          Personal Data may be disclosed, for the purposes
                          listed above (where applicable), to the following
                          third parties, whether they are located overseas or in
                          Singapore;
                        </div>
                        <ol className={layout.letterList}>
                          <li>Edge Digital affiliates;</li>
                          <li>
                            agents, contractors or third party service providers
                            such as telecommunications, information technology,
                            payment, payroll, processing, training, market
                            research, storage, archival or other services to
                            Edge Digital;
                          </li>
                          <li>
                            service providers providing you with goods and
                            services, managing your subscriptions, renewals and
                            your account, processing payments, addressing
                            questions and feedback, improving our products and
                            services, as well as where permitted under law,
                            sending you marketing and promotional offers on
                            products and services, and personalised content and
                            advertising based on your preferences or
                            demographics
                          </li>
                          <li>
                            vendors or any third party business partners who
                            offer goods and services or sponsor contests or
                            other promotional programs on Edge Digital’s sites;
                          </li>
                          <li>
                            external business and charity partners in relation
                            to corporate promotional events;
                          </li>
                          <li>
                            any business partner, investor, assignee or
                            transferee (actual or prospective) to facilitate
                            business asset transactions (which may extend to any
                            merger, acquisition or asset sale);
                          </li>
                          <li>
                            anyone whom we transfer or may transfer our rights
                            and duties;
                          </li>
                          <li>
                            our professional advisors such as our auditors and
                            lawyers;
                          </li>
                          <li>
                            relevant government regulators or authority or law
                            enforcement agency to comply with any laws or rules
                            and regulations imposed by any governmental
                            authority; and any other party to whom you authorise
                            us to disclose your personal data.
                          </li>
                          <li>
                            In addition, if you have registered your phone
                            number with the national Do Not Call (DNC) registry,
                            we will not send you promotional and marketing
                            messages via your telephone number unless you have
                            provided Edge Digital consent to do so.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold">Deemed Consent</div>
                    <ol className={layout.orderedList + " FS14"}>
                      <li className={layout.az}>
                        <div className="fbold">
                          In addition to the matters set forth above, subject to
                          and in accordance with applicable law, you shall be
                          deemed to have consented to Edge Digital collecting,
                          using, disclosing and sharing amongst ourselves your
                          Personal Data, and disclosing such Personal Data to
                          Edge Digital’s authorised service providers and
                          relevant third parties:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            where in response to a request for your Personal
                            Data in connection with identified purposes, you
                            voluntarily provide such Personal Data to us for
                            such purpose(s);
                          </li>
                          <li>
                            where the collection, use or disclosure of your
                            Personal Data is reasonably necessary for the
                            conclusion and/or performance of a contract between
                            you and us or any other organisation entered into at
                            your request, which may include recipients of your
                            Personal Data not indicated in this Privacy Policy;
                            and/or
                          </li>
                          <li>
                            where we have brought to your attention our
                            intention to collect, use or disclose your personal
                            data for identified purposes, and you have not taken
                            any action to opt out within the period specified in
                            our notices. For this purpose, you agree that we may
                            provide you such notices via Edge Digital’s sites,
                            or via other forms of communication (e.g. SMS or
                            email) based on the contact particulars that we may
                            have of you in our records from time to time.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold ">
                      Other Bases for Handling or Processing Your Personal Data
                    </div>
                    <ol className={layout.orderedList}>
                      <li className={layout.az}>
                        <div className="fbold">
                          In addition to and without limiting the consents you
                          have provided to our collection, use and disclosure of
                          your Personal Data for the purposes set out elsewhere
                          in this Privacy Policy, where permitted by applicable
                          law, Edge Digital may also in accordance with the
                          requirements thereof also collect, use and/or disclose
                          your Personal Data as further detailed below including
                          without your further consent, where we meet the
                          requirements of applicable law:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            for our legitimate interests or the legitimate
                            interests of any other person, including but not
                            limited to protecting the life, health and property
                            safety of individuals in an emergency, and other
                            purposes expressly set forth in this Privacy Policy;
                            and/or
                          </li>
                          <li>
                            for improving our products, services, processes or
                            business, understanding customer preferences and
                            personalising experiences and recommendations, based
                            on your Personal Data records with Edge Digital.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold">
                      Use of Cookies and Related Technologies
                    </div>
                    <ol className={layout.orderedList}>
                      <li className={layout.az}>
                        <div className="fbold">
                          When you interact with us on our sites, applications
                          or services, we may collect or analyse anonymised
                          information from which individuals cannot be
                          identified (“Aggregate Information”), such as number
                          of users and their frequency of use, the number of
                          page views (or page impressions) that occur on the
                          site and common entry and exit points of the site.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          Our sites use cookies, and other related technologies
                          which have different functions. They are either
                          necessary for the functioning of our services, help us
                          improve our performance, or serve to provide you with
                          extra functionalities.
                        </div>
                        <div className="mt-4">
                          <div>
                            <b>Cookies</b> – Small data file which is sent to
                            your browser to store and track Aggregate
                            Information about you when you enter our sites. The
                            cookie is used to track information such as the
                            number of users and their frequency of use, profiles
                            of users and their preferred sites.
                          </div>
                          <div className="mt-3">
                            Cookies can be disabled or removed by tools that are
                            available in most commercial browsers. The
                            preferences for each browser you use will need to be
                            set separately and different browsers offer
                            different functionality and options.
                          </div>
                        </div>
                        <div className="mt-4">
                          <div>
                            <b>Web beacons</b> - Small graphic images (also
                            known as "pixel tags" or "clear GIFs") that are not
                            apparent to the user. Tracking links and/or similar
                            technologies consist of a few lines of programming
                            code and can be embedded in our sites or platforms.
                            Web beacons are usually used in conjunction with
                            cookies and primarily used for statistical analysis
                            purposes. This technology can also be used for
                            tracking traffic patterns on sites and platforms, as
                            well as finding out if an e-mail has been received
                            and opened and to see if there has been any
                            response.
                          </div>
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          Edge Digital may use the terms "cookies" or "related
                          technologies" interchangeably in our policies to refer
                          to all technologies that we may use to collect or
                          store information in your browser or device or that
                          collect information or assist in identifying you as a
                          unique user in the manner described above.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          We may use an independent company (the “Third Party
                          Market Research Company”) to measure and analyse the
                          Internet usage across Edge Digital’s sites. Edge
                          Digital uses the Third Party Market Research Company’s
                          services to collect the following core information on
                          the usage of our sites, including:
                        </div>
                        <ol className={layout.letterList}>
                          <li>
                            the number of page views (or page impressions) that
                            occur on Edge Digital’s sites;
                          </li>
                          <li>
                            the number of unique visitors to Edge Digital’s
                            site;
                          </li>
                          <li>
                            how long these unique visitors spend on Edge
                            Digital’s sites when they do visit; and
                          </li>
                          <li>
                            common entry and exit points into Edge Digital’s
                            sites.
                          </li>
                        </ol>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          This information is provided to Edge Digital to assist
                          in analysing the usage of our sites.
                        </div>
                      </li>
                      <li className={layout.az}>
                        <div className="fbold">
                          Should you wish to disable the cookies associated with
                          these technologies, you may do so by changing the
                          setting on your browser. However, you may not be able
                          to enter certain part(s) of our sites.
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold">Third-Party Sites</div>
                    <ol className={layout.orderedList + " FS14"}>
                      <li className="fbold">
                        Our sites may contain links to other websites operated
                        by third parties, such as our business partners. We are
                        not responsible for the privacy practices of websites
                        operated by third parties that are linked to our site.
                        We encourage you to learn about the privacy policies of
                        such third party websites. Some of these third party
                        websites may be co-branded with our logo or trademark,
                        even though they are not operated or maintained by us.
                        Once you have left our site, you should check the
                        applicable terms, conditions and policies of the third
                        party website to determine how they will handle any
                        information they collect from you.
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold">Retention of Your Personal Data</div>
                    <ol className={layout.orderedList + " FS14"}>
                      <li className="fbold">
                        We will store your Personal Data only to the extent
                        necessary for legal and our business purposes, including
                        for the purposes set forth in this Privacy Policy,
                        except as provided otherwise by applicable laws. When
                        there is no longer any business or legal purpose for
                        retaining your Personal Data or the statutory retention
                        period expires according to applicable laws, we will
                        delete or anonymize your Personal Data.
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                    <div className="fbold">
                      Withdrawal, Access and Correction of Your Personal Data
                    </div>
                    <ol className={layout.orderedList + " FS14"}>
                      <li className="fbold">
                        Should you wish to withdraw consent to use of your
                        Personal Data or obtain access to or make corrections to
                        your Personal Data records, please log in to the
                        relevant account through which the Personal Data was
                        provided, if any, failing which please contact the
                        relevant Personal Data Protection Officer, contact
                        details of which are found in the{" "}
                        <a href="/legal-notice">Legal Notice</a> or similar
                        document elsewhere on our sites.
                      </li>
                      <li className="fbold">
                        Please note that if your Personal Data has been provided
                        to us by a third party, you should contact such third
                        party directly to make any queries, feedback, and access
                        and/or correction requests to Edge Digital on your
                        behalf.
                      </li>
                      <li className="fbold">
                        Please note that if you withdraw your consent to any or
                        all use of your Personal Data or request to delete your
                        Personal Data, depending on the nature of your request,
                        we may not be in a position to continue to provide its
                        products or services to you or administer any
                        contractual relationship in place, and this may also
                        result in the termination of any agreements with us and
                        your being in breach of your contractual obligations or
                        undertakings, and Edge Digital’s legal rights and
                        remedies in such event are expressly reserved.
                      </li>
                      <li className="fbold">
                        To the extent as permitted by applicable laws, we may
                        not be able to respond to your request to exercise your
                        rights in certain circumstances. For instance, if your
                        request is contrary to our obligations under applicable
                        laws, or may infringe our trade secrets, we may refuse
                        to respond to your request. In addition, if we have
                        sufficient evidence of any malice or abuse of rights, or
                        that our response to your request would infringe the
                        significant legitimate rights and interests of other
                        individuals, we may also decline your request.
                      </li>
                    </ol>
                  </li>
                  <li className="mt-4">
                     <div className="fbold">
                     Governing Laws
                     </div>
                     <ol className={layout.orderedList+" FS14"}>
                        <li className="fbold">
                          This Privacy Policy shall be governed in all respects by the laws of Singapore.
                        </li>
                     </ol>
                  </li>
                </ol>
              </Row>
            </Card.Body>
           </div>
          </Card>
        </Col>
      </Row>
    </Log>
  );
}
