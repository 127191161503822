const defaultShape = {
  event: "event",
  user: {
    user_id: "userId",
    user_agent: "userAgent",
  },
  device: {
    type: "type",
    resolution: "resolution",
  },
  page: {
    title: "title",
    url: "url",
    referrer: "referrer",
    timestamp: "timestamp",
    utm_attr: {
      source: "source",
      medium: "medium",
      campaign: "campaign",
      term: "term",
      content: "content",
    },
  },
  prev: {
    title: "prevTitle",
    url: "prevUrl",
    timestamp: "prevTimestamp",
    event: "prevEvent",
    user_id: "prevUserId",
    tracking_code: "prevTrackingCode",
  },
  analytic: {
    tracking_code: "trackingCode",
  },
  session: {
    session_id: "sessionId",
    session_start: "sessionStart",
    tab_number: "tabNumber"
  }
};

const clickDefaultShape = {
  click: {
    element_id: "elementId",
    element_type: "elementType",
    element_text: "elementText",
  }
}

//use custom props with object to require a prop:value..

const shapes = {
  'page-view': {
    ...defaultShape,
  },
  'click': {
    ...defaultShape,
    ...clickDefaultShape
  },
  'vote': {
    ...defaultShape,
    ...clickDefaultShape,
    custom: {
      reason: 'reason'
    }
  },
  'insight': {
    ...defaultShape,
    ...clickDefaultShape,
    custom: {
      insight_approved: 'insightApproved',
    }
  }
};

export default shapes;
