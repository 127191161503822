import { UAParser } from "ua-parser-js";
import moment from "moment";
import SHAPES from "./dataShapes";
import { set, forEach } from "lodash";
import { flatten } from "flat";
import axiosConfig from "../helpers/axiosConfig";
import { setCookie, getCookie, setLocal, getLocal, setSession, getSession } from "../helpers/browserHelper"
import { v4 as uuidv4 } from 'uuid';

const MAIN_EVENTS = ['page-view', 'click'] //first index of an event when .split('_') shoulde be this eg: click_register_user
const CLICK_SUB_EVENTS = ['register', 'login', 'publish', 'insight', 'vote']; //click events with more than 1 words should be joined by _ eg: register_user
const CLICK_SUB_EVENT_CUSTOM_SHAPE = ['vote', 'insight']; //not all sub events have custom shape

const ENV = process.env.REACT_APP_ENVIRONMENT;

let url = ENV === 'production' ? 'https://api.onepeople.online/op/v1/logs' : 'http://localhost:3020/op/v1/logs'; //replace with OnePeople log API

const GENERAL_ERR = new Error('An error occurred!');


function genRandomUserID() {
  return `user-${uuidv4()}`;
}

function genSession(type = 'id') {
  return `${uuidv4()}`;
}

function getUserDetails() {
  let userId = getCookie("anonId");
  if (!userId) {
    setupConfig();
  }

  return {
    userAgent: navigator.userAgent,
    userId: getCookie("anonId"),
  };
}

function getCurrentTimestamp() {
  return moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");
}

function getPageDetails(payload) {
  let timestamp = getCurrentTimestamp();
  return {
    url: payload.url ?? window.location.href,
    referrer: document.referrer.length > 0 ? document.referrer : null,
    timestamp,
    title: payload.page ?? document.title,
  };

}

function getPrevEvent() {

  let last_event = getSession('prev_event');
  let prev_event = {};

  const session_id = getCookie('sessionId');
  const tab_number = getSession('tabNumber');

  //set res props if there is prev_event pulled the same with the current session id and number else return empty object
  if (last_event) {
    if (last_event.session.session_id === session_id && last_event.session.tab_number === tab_number) {
      prev_event.prevTitle = last_event.page.title;
      prev_event.prevUrl = last_event.page.url;
      prev_event.prevTimestamp = last_event.page.timestamp;
      prev_event.prevEvent = last_event.event;
      prev_event.prevUserId = last_event.user.user_id;
      prev_event.prevTrackingCode = last_event.analytic.tracking_code;
    }
  }
  return prev_event;
}

function getUtmAttr() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    source: urlParams.get("utm_source") ?? null,
    medium: urlParams.get("utm_medium") ?? null,
    campaign: urlParams.get("utm_campaign") ?? null,
    term: urlParams.get("utm_term") ?? null,
    content: urlParams.get("utm_content") ?? null,
  };
}

const getDeviceType = (ua) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

function getDeviceDetails() {
  return {
    type: getDeviceType(UAParser()),
    resolution: `${window.screen.width}x${window.screen.height}`,
  };
}

function getAnalyticDetails(code) {
  return {
    trackingCode: code ?? null,
  };
}

function getSessionDetails() {

  const sessionId = getCookie('sessionId');
  const tabNumber = getSession('tabNumber');
  const sessionStart = getCookie('sessionStart');

  return {
    sessionId,
    sessionStart,
    tabNumber
  }
}

function setupDefaultPayload(payload) {
  delete payload.children;
  delete payload.logImpression;

  const user = getUserDetails();
  const device = getDeviceDetails();
  const page = getPageDetails(payload);
  const utms = getUtmAttr();
  const analytic = getAnalyticDetails(payload.trackingCode);
  const prev_event = getPrevEvent();
  const session = getSessionDetails();

  return { ...payload, ...user, ...device, ...page, ...utms, ...analytic, ...prev_event, ...session };
}

function flipObjectKeyValue(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [value, key])
  );
}

function checkString(str) {
  return !str || str === "" ? false : true;
}

function setupConfig() {
  //check if cookie anonId exist
  let anonId = getCookie("anonId");
  if (!anonId) {
    //setup anonymouse userid
    anonId = genRandomUserID();
    //save to cookie
    setCookie("anonId", anonId, 400);
  }
}

function isValueValid(value) {
  if (value === undefined || value === null || value === '') {
    return false;
  }
  return true;
}

// Function to check if all properties have values
function allPropsHaveValues(object) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      let value = object[key];
      if (!isValueValid(value)) {
        return false;
      }
    }
  }
  return true;
}

function startSession() {
  // console.log('---started session')
  let session_id = genSession('id');
  setCookie('sessionId', session_id, 400);
  setCookie('sessionStart', getCurrentTimestamp(), 400);
}


function sessionCountedCheck() {
  let current_active = getLocal('activeSession');
  let set_active_ctr = getSession('setActive');

  if (set_active_ctr || set_active_ctr === null) {
    // console.log('---addSessionCount')
    current_active === null ? setLocal('activeSession', 1) : setLocal('activeSession', (parseInt(current_active) + 1))
  }
  setSession('setActive', false);

}

function sessionIdCheck() {
  let session_id = getCookie('sessionId');
  //create session if no session id found OR expired (no action for more than 30 minutes)
  if (session_id === null) {
    // console.log('---session id is null')
    startSession();
  }
}

function lastSessionCheck() {

  let session_last = getCookie('sessionLast');
  let now = getCurrentTimestamp();

  if (session_last) {
    // console.log('---session last is found')
    let last_event = new Date(session_last);
    let current_ts = new Date(now);
    let result_ms = current_ts.getTime() - last_event.getTime();

    //create session if last event is past 30 minutes
    if ((result_ms / 1000 / 60) >= 30) {
      startSession();
    }
  }
}

function tabNumberCheck() {
  const tab_number = getSession('tabNumber');
  const session_active = getLocal('activeSession');
  if (!tab_number) {
    //if active session is also not present, start a new session as user entered the site without any other site tab
    //which means user quit and decided to comeback via url or any means
    if (session_active === null || session_active === 0) {
      startSession();
    }
    const session_number = genSession('number');
    setSession('tabNumber', session_number);
  }
}

function checkSessionStatus() {
  console.log('---session id: ', getCookie('sessionId'))
  console.log('---session number: ', getSession('tabNumber'))
  console.log('---session start timestamp: ', getCookie('sessionStart'))
  console.log('---session last timestamp: ', getCookie('sessionLast'))
  console.log("---getLocal('activeSession'): ", getLocal('activeSession'))
  // console.log(`---getSession('setActive')`, getSession('setActive'))
}

function setupSession() {

  //check if session is initialized
  sessionIdCheck();

  //compute if 30 minutes past since the last request and start another session if it is
  lastSessionCheck();

  //make sure to create session number if not present
  tabNumberCheck();

  //make sure session is counted to help when to reset session or not
  sessionCountedCheck();

  //console different session status
  // checkSessionStatus();

}

/**
 * @param {object} options Payload for logging
 * @param {string} options.event Name of the event triggered ie: page-view, click*
 * @param {string} options.page Name of the page the event was triggered in. Will use the html title tag if no page is provided.
 * @param {string} options.url URL Path of the page if needed to specify
 * @param {string} options.elementType Required if event is click
 * @param {string} options.elementId Required if event is click
 * @param {string} options.elementText Required if event is click
 * @param {string} options.customValue Custom props and value depends on the custom event specified in the shape eg: insightApproved=true
 */
function sendLog(options) {
  send(options);
}

function send(options) {
  try {
    if (getCookie('consentAgreement') !== "Accept") return;

    setupSession();

    options.event = options.event.toLowerCase();

    let event_split = options.event.split('_');
    let event = event_split[0];
    let custom_event = event_split.length > 1 ? event_split.slice(1).join('_') : null;
    let has_click_custom_shape = CLICK_SUB_EVENT_CUSTOM_SHAPE.includes(custom_event);

    //validate event
    if (!MAIN_EVENTS.includes(event)) {
      throw GENERAL_ERR;
    }

    //as of now, only click has sub events so any page-view with sub event is error
    if (event === 'page-view' && event_split.length > 1) {
      throw GENERAL_ERR;
    }

    if (event === "click") {
      if (
        !checkString(options.elementType) ||
        !checkString(options.elementId) ||
        !checkString(options.elementText)
      ) {
        throw GENERAL_ERR;
      }

      if (event_split.length > 1 && (!custom_event || !CLICK_SUB_EVENTS.includes(custom_event))) {
        throw GENERAL_ERR;
      }
    }

    let payload = setupDefaultPayload(options);

    let shape = custom_event && has_click_custom_shape ? SHAPES[custom_event] : SHAPES[event];
    if (!shape) {
      throw GENERAL_ERR;
    }


    //flatten and flip the object key value pair to be used as tagPath later
    let flattened_shape = flipObjectKeyValue(flatten(shape));

    //get the final payload
    const final_payload = {};
    forEach(payload, (propValue, propKey) => {
      const tagPath = flattened_shape[propKey];
      if (tagPath) {
        set(final_payload, tagPath, propValue ?? null);
      }
    });

    // console.log('---finalPayloadShape');
    // console.log(final_payload);

    //make sure that custom payloads have values as it is user provided
    if (custom_event && has_click_custom_shape) {
      if (!final_payload.custom || !allPropsHaveValues(final_payload.custom)) {
        throw GENERAL_ERR;
      }

      let custom_shape = shape.custom;
      let final_custom_shape = final_payload.custom;
      for (let key in custom_shape) {
        if (!isValueValid(final_custom_shape[key])) {
          throw GENERAL_ERR;
        }
      }
    }

    //save for current event for engagement processing later on
    setSession('prev_event', final_payload);

    //set session last timestamp every new event logging
    setCookie('sessionLast', getCurrentTimestamp(), 400);

    axiosConfig
      .post(url, { payload: final_payload })
      .then((res) => {
        // console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (e) {
    console.log(e)

    if (e?.message === "An error occurred!") {
      console.log("---Invalid log data");
    }
  }
}

export {
  sendLog
};
