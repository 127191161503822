import { motion } from "framer-motion";
import validator from "validator";
import { useState, useEffect } from "react";
import auth from "../../helpers/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../../helpers/axiosConfig";
import ModalHeader from "../others/ModalHeader";
import CountDown from "../others/CountDown";
import NotificationHelper from "../../helpers/notificationsHelper";
import serverTimeHelper from "../../helpers/serverTimeHelper";
import { useDispatch } from "react-redux";
import { setOrigServerTime } from "../../helpers/serverTime";
import { sendLog } from "../../analytics/analytics";

function ModalLogin2FAEmail(props) {
  const { email, password, setIsOnline, setShowMainModal, step, setStep, isAlpha, email2FAError, setEmail2FAError } = props;
  const dispatch = useDispatch();
  const [queryParameters] = useSearchParams();
  const OpenningAnimation = { scale: 1, translateY: "-50%" };
  const ClosingAnimation = { scale: 0, translateY: "-50%" };
  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(true);
  const [verifying, setVerifying] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [isCounting, setIsCounting] = useState(true); //true = counting / false = done counting
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(
    () => {
      send2FA();
      setError(email2FAError);
      setIsSending(false);
      setIsCounting(true);
    },
    // eslint-disable-next-line
    []
  );

  function handleChange(e) {
    let val = e.target.value;
    if (!validator.isNumeric(val) && val.length > 0) return;
    if (!validator.isLength(val, { min: 0, max: 6 })) {
      return;
    }
    setCode(val);
    setError(null);
  }

  function send2FA() {
    if (isSending) return;
    setError(null);
    setIsSending(true);

    if (isEmailDisabled) {
      NotificationHelper.disabledEmailNotif(
        "We are unable to send you an email because there may be a problem with your email service provider.",
        "Please verify your 2FA with the code provided in the authenticator app to sign in."
      );
      setError("Unable to send 2FA code");
      setIsSending(false);
      setIsCounting(true);
      return
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3003/op/v1/get-otp?email=${email}` : `https://api.onepeople.online/op/v1/get-otp?email=${email}`;
    axiosConfig
      .get(url)
      .then((res) => {
      })
      .catch((err) => {

        let datas = err.response?.data;
        let errs = datas?.errors;
        let errorTracker = null;
        if (errs) {
          if (errs[0].msg === "connect ETIMEDOUT") {
            errorTracker = "Something went wrong. Please try again.";

          } else if (errs[0].msg === "Unable to send 2FA code") {
            if (datas.disabled !== undefined && datas.disabled) {
              NotificationHelper.disabledEmailNotif(
                "We are unable to send you an email because there may be a problem with your email service provider.",
                "Please verify your 2FA with the code provided in the authenticator app to sign in."
              );
              setIsEmailDisabled(true);
            }
            errorTracker = "Unable to send 2FA code";
          } else {
            errorTracker = errs[0].msg;
          }
        } else {
          errorTracker = "Something went wrong. Please try again.";
        }
        setError(errorTracker);
      })
      .finally(() => {
        setIsSending(false);
        setIsCounting(true);
      })
  }

  function resend2FA(e) {
    e.preventDefault();
    if (isCounting || isSending) return;
    send2FA();
  }

  function onSubmit(e) {
    e.preventDefault();

    let hasError = false;
    let errorTracker = error;

    if (
      validator.isEmpty(code) ||
      !validator.isLength(code, { min: 6, max: 6 })
    ) {
      errorTracker = "The provided code is incorrect";
      hasError = true;
    }

    setError(errorTracker);
    if (hasError) return;

    if (verifying) return;
    setVerifying(true);
    const userdata = {
      query: "login",
      email,
      password,
      otp: code,
    };
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3003/op/v1/verify?from=email" : "https://api.onepeople.online/op/v1/verify?from=email";
    axiosConfig
      .post(url, userdata)
      .then((res) => {
        if (res.data.success) {
          auth.setToken(res.data.token);
          auth.setFullName(res.data.user.fullname);
          auth.setId(res.data.user.id);

          serverTimeHelper.set(res.data.time)
          dispatch(setOrigServerTime(res.data.time));
          if (step === 'register_verify_email') {
            sendLog({event: "click_register", page: "Register", elementId: "register-btn", elementType: "button", elementText: "Confirm"});
            setStep("register_welcome");
            setTimeout(() => {
              setIsOnline(auth.isOnline());
              navigate("/");
            }, 5000)
          } else {
            sendLog({event: "click_login", page: "Login", elementId: "login-btn", elementType: "button", elementText: "Confirm"});
            setIsOnline(auth.isOnline());
            toggleOffInContainer();
            let redirect_path = queryParameters.get("redirect");
            if (redirect_path) {
              window.location.href = redirect_path;
            } else {
              navigate("/");
            }
          }
        }
      })
      .catch((err) => {

        let errs = err.response.data.errors;
        if (errs) {
          if (errs[0].msg === "connect ETIMEDOUT") {
            errorTracker = "Something went wrong. Please try again.";
          } else {
            errorTracker = "The provided code is incorrect";
          }
        } else {
          errorTracker = "Something went wrong. Please try again.";
        }
        setError(errorTracker);
      })
      .finally(() => {
        setVerifying(false);
      });
  }

  const toggleOffInContainer = () => {
    setEmail2FAError(null);
    setStep(null)
    if (isAlpha) {
      setStep(null)
    } else {
      setShowMainModal(false);
    }
  };

  function verifyAuthApp() {
    setStep("login_verify");
  }

  return (
    <motion.div>
      <motion.div
        initial={{ scale: 0.5, translateY: "-50%" }}
        animate={isOpen ? OpenningAnimation : ClosingAnimation}
        transition={{ type: "spring", duration: 0.3 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="op-box p-3 py-5 auth-container OPModalBox position-absolute mx-auto"
      >
        <ModalHeader
          title="Enter your 2FA code sent via email below"
          toggleOffInContainer={toggleOffInContainer}
        />
        <div
          className={`row px-3 login-register align-items-center justify-content-center`}
        >
          <div className={`login-container col-12 col-md-6 col-lg-6 px-5`}>
            <form
              autoComplete="off"
              onSubmit={onSubmit}
              className="position-relative mt-5"
            >
              <h4
                className="position-absolute d-inline-block px-1 text-black modal-sub-header"
                style={{ top: "-1.875rem", left: "0" }}
              >
                <span className="me-2">&#128100;</span>
                Sign In
              </h4>
              <div className="pt-3">
                <div className="m-0 p-0 d-flex justify-content-between">
                  <p className="m-0 p-0 input-text input-error text-gray">
                    2FA code
                  </p>
                  {error && (
                    <p className="m-0 p-0 input-text text-red">{error}</p>
                  )}
                </div>
                <input
                  value={code}
                  onChange={handleChange}
                  type="text"
                  name="code"
                  className="form-control hl-border"
                  autoComplete="off"
                />
              </div>
              <div className={`text-end mt-2 ${step === 'login_verify_email' ? 'd-flex justify-content-between' : ''}`}>
                {step === 'login_verify_email' && (
                  <button
                    type="button"
                    onClick={verifyAuthApp}
                    className={`op-link-btn input-text bounce`}
                  >
                    {`Verify code via Auth App `}
                  </button>
                )}
                <button
                  type="button"
                  onClick={resend2FA}
                  className={`op-link-btn input-text bounce ${isSending || isCounting ? "disabled" : ""}`}
                >
                  {`Resend via Email `} {isCounting && <CountDown timer={60} setStatus={setIsCounting} />}
                </button>
              </div>
              <div className="mt-5 text-center">
                <motion.input
                  initial={{}}
                  animate={
                    verifying
                      ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                      : {}
                  }
                  type="submit"
                  name="submit"
                  value="Confirm"
                  className="op-btn confirm-btn bounce"
                />
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
export default ModalLogin2FAEmail;
