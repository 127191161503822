import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modal from "../../../components/Modal";
import MetadataSetup from "../../../components/MetadataSetup";
import ReactRouterPrompt from "react-router-prompt";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import EmailVerification from "../../../components/profile/cards/EmailVerification";
import SetUp2FA from "../../../components/profile/cards/SetUp2FA";
import OPSpinner from "../../../components/others/OPSpinner";
import UserCardDetails from "./UserCardDetails";
import EmailVerified from "../../../components/profile/cards/EmailVerified";
import TriangleCrossImage from "../../../assets/images/profile/red-triangle.svg";

import cross from "../../../assets/images/others/cross.svg"
import Log from "../../../components/analytics/Log";
function UserDetails() {
  const [userData, setUserData] = useState({});
  const [verifiedStatus, setVerifiedStatus] = useState(null);
  const [errorRetrieving, SetErrorRetrieving] = useState(false);
  const [detailChanged, SetDetailChanged] = useState(false);
  const [emailSendingStatus, SetEmailSendingStatus] = useState();
  useEffect(() => {
    const handleUnload = (e) => {
      if (detailChanged) {
        e.preventDefault();
        return (e.returnValue = "Are you sure you want to leave?");
      }
    };

    const handlePopState = (e) => {
      if (detailChanged) {

        e.preventDefault();
        // eslint-disable-next-line
        const confirmed = window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        );
        return (e.returnValue = "Are you sure you want to leave?");
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("navigate", handlePopState);
    SetEmailSendingStatus(userData.disable_email);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("navigate", handlePopState);
    };
  }, [detailChanged, emailSendingStatus, SetEmailSendingStatus, userData]);
  return (
    <Log logImpression event="page-view" page="Profile">
      <ReactRouterPrompt when={detailChanged}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal>
            <motion.div
              transition={{ duration: 0.1 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="unsave-modal crop-container" onClick={onCancel}>
              <div className="unsave-details text-center" onClick={e => e.stopPropagation()}>
                <span className="close" onClick={onCancel}>
                  <img
                    src={cross}
                    className="exit-icon"
                    alt="Exit Modal"
                  />
                </span>
                <h3 className="mb-5 text-black bold">
                  Your details were not updated.
                </h3>
                <h4 className="text-black bold mb-4">📝 Unsaved Changes</h4>
                <div className="triangle">
                  <div>
                    <img src={TriangleCrossImage} alt="Unsaved Changes Warning" />
                  </div>
                </div>
                <div className="mt-4 text-black">
                  <p className="mb-0 text-black">
                    Are you sure you want to leave this page?
                  </p>
                  <p className="mb-0 text-black">
                    Changes you have made will not be saved.
                  </p>
                </div>
                <div className="mt-5">
                  <button onClick={onCancel}>Stay</button>
                  <button onClick={onConfirm} className="leave">Leave</button>
                </div>
              </div>
            </motion.div>
          </Modal>
        )}
      </ReactRouterPrompt>
      <MetadataSetup
        title="Profile | OnePeople"
        canonical="https://onepeople.online/profile/"
        description="Profile Page of OnePeople Online"
      />
      {(userData.email === "" ||
        (userData.email === undefined && errorRetrieving !== true)) && (
          <div className="d-flex flex-wrap p-5 justify-content-center">
            <div>Fetching data...</div>

            <div className="col-12 justify-content-center d-flex">
              <OPSpinner />
            </div>
          </div>
        )}
      {errorRetrieving === true && (
        <div className="d-flex flex-wrap p-5 justify-content-center">
          <div>
            Oops! something went wrong! <br />
            Click here to{" "}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
            >
              refresh
            </a>{" "}
            the page
          </div>
        </div>
      )}

      <div className="main profile d-flex">
        <Row className="flex-fill pb-3">
          {verifiedStatus !== null &&
            (verifiedStatus === true ? (
              <Col lg={4} className="mt-1 mt-md-1 mt-lg-0">
                <EmailVerified />
              </Col>
            ) : (
              <Col lg={4} className="mt-1 mt-md-1 mt-lg-0">
                <EmailVerification email={userData.email} userData={userData} />
              </Col>
            ))}
          {(userData.email !== "" && userData.email !== undefined) && (
            <Col lg={4} className="mt-4 mt-lg-0">
              <SetUp2FA
                email={userData.email}
                emailSendingStatus={emailSendingStatus}
                setVerifiedStatus={setVerifiedStatus}
              />
            </Col>
          )}

          <Col lg={4} className="mt-4 mt-lg-0">
            <UserCardDetails
              setUserData={setUserData}
              setVerifiedStatus={setVerifiedStatus}
              SetErrorRetrieving={SetErrorRetrieving}
              SetDetailChanged={SetDetailChanged}
              SetEmailSendingStatus={SetEmailSendingStatus}
            />
          </Col>
        </Row>
      </div>
    </Log>
  );
}

export default UserDetails;
